import "../scss/style.scss";

jQuery(function ($) {

  // js-fixed-contact
  $(function(){
    $(window).scroll(function () {
      if($(this).scrollTop() >= 300) {
        $('.js-fixed-contact').addClass('is-show');
      } else {
        $('.js-fixed-contact').removeClass('is-show');
      }
    });
  });

});
